<template>
	<v-card class="LabelValueGrid elevation-5 pa-5">
		<v-row class="pa-2">
			<v-col cols="12" sm="5">
				<span class="font-weight-bold">{{ $t("label_NameSurname") }}</span>
			</v-col>

			<v-col cols="12" sm="5" v-if="!editNameSection.showForm">
				<span v-text="profile.Name + ' ' + profile.Surname"></span>
			</v-col>
			<v-col
				cols="12"
				sm="2"
				class="text-right mt-2 mt-sm-0"
				v-if="!editNameSection.showForm"
			>
				<v-btn class="secondary width-xs-100" @click="ShowEditNameSection()">{{
					$t("common.edit")
				}}</v-btn>
			</v-col>

			<v-col cols="12" sm="5" v-if="editNameSection.showForm">
				<v-form data-vv-scope="formEditAuthor" v-if="metadataLoaded">
					<v-text-field
						v-model="editNameSection.name"
						data-vv-scope="formEditAuthor"
						data-vv-delay="300"
						:label="$t('yourName')"
						:counter="metadata['Name'].MaxLength"
						v-validate="metadata['Name'].ClientValidators"
						:error-messages="errors.collect('formEditAuthor.name')"
						data-vv-name="name"
						:data-vv-as="$t('yourName')"
					></v-text-field>

					<v-text-field
						v-model="editNameSection.surname"
						:label="$t('yourSurname')"
						:counter="metadata['Name'].MaxLength"
						v-validate="metadata['Name'].ClientValidators"
						:error-messages="errors.collect('formEditAuthor.surname')"
						data-vv-name="surname"
						:data-vv-as="$t('yourSurname')"
					></v-text-field>
					<v-row class="pa-2">
						<v-col cols="12" class="text-right px-3">
							<v-btn
								color="primary"
								class="width-xs-50"
								@click="UpdateNameSurname()"
								:loading="editNameSection.loadingData"
								>{{ $t("common.update") }}</v-btn
							>

							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										class="ml-3"
										icon
										color="grey"
										@click="HideEditNameSection()"
										:loading="editNameSection.loadingData"
									>
										<v-icon>fas fa-times</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("common.cancel") }}</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>

		<v-divider></v-divider>
		<v-row class="pa-2">
			<v-col cols="12" sm="5">
				<span class="font-weight-bold">{{ $t("label_Email") }}</span>
			</v-col>
			<v-col cols="12" sm="5" v-if="!editEmailSection.showForm">{{
				profile.AspNetUser.Email
			}}</v-col>
			<v-col
				cols="12"
				sm="2"
				v-if="!editEmailSection.showForm"
				class="text-right mt-2 mt-sm-0"
			>
				<v-btn class="secondary width-xs-100" @click="ShowEmailSection()">{{
					$t("common.edit")
				}}</v-btn>
			</v-col>

			<!-- TODO: from metadata? -->
			<v-col cols="12" sm="5" v-if="editEmailSection.showForm">
				<v-form data-vv-scope="formEmail" v-if="metadataLoaded">
					<v-text-field
						v-model="editEmailSection.email"
						data-vv-scope="formEmail"
						data-vv-delay="300"
						:label="$t('Email')"
						:counter="50"
						v-validate="'required|email'"
						:error-messages="errors.collect('formEmail.email')"
						data-vv-name="email"
						:data-vv-as="$t('Email')"
					></v-text-field>

					<v-row class="pa-2">
						<v-col cols="12" class="text-right px-3">
							<!-- <v-btn
								text
								color="secondary"
								class="width-xs-50"
								@click="HideEmailSection()"
								:loading="editEmailSection.loadingData"
								>{{ $t("common.cancel") }}</v-btn
							> -->
							<v-btn
								color="primary"
								class="width-xs-50"
								@click="UpdateUserEmail()"
								:loading="editEmailSection.loadingData"
								>{{ $t("common.update") }}</v-btn
							>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										class="ml-3"
										icon
										color="grey"
										@click="HideEmailSection()"
										:loading="editEmailSection.loadingData"
									>
										<v-icon>fas fa-times</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("common.cancel") }}</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</v-form>
			</v-col>
		</v-row>

		<v-divider></v-divider>

		<v-row class="pa-2">
			<v-col cols="12" sm="5">
				<span class="font-weight-bold">{{ $t("label_Password") }}</span>
			</v-col>
			<v-col cols="12" sm="5">
				<v-icon v-for="index in 8" :key="index" size="12">fas fa-asterisk</v-icon>
			</v-col>
			<v-col cols="12" sm="2" class="text-right mt-2 mt-sm-0">
				<v-btn class="secondary width-xs-100" to="/Account/ChangePassword">{{
					$t("common.edit")
				}}</v-btn>
			</v-col>
		</v-row>

		<v-divider></v-divider>

		<v-row class="pa-2">
			<v-col cols="12" sm="5">
				<span class="font-weight-bold">{{ $t("label_Mobile") }}</span>
			</v-col>
			<v-col cols="12" sm="5" v-if="!editMobilePhoneSection.showForm">
				{{ profile.MobilePhoneNo ? profile.MobilePhoneNo : "--" }}

				<template v-if="false">
					<span v-if="profile.IsMobilePhoneVerified"></span>

					<span v-if="profile.MobilePhoneNo && !profile.IsMobilePhoneVerified">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-btn
									small
									color="primary"
									@click="StartVerification"
									:loading="sendingSMS"
									v-on="on"
									>{{ $t("VerifyNow") }}</v-btn
								>
							</template>
							<span>{{ $t("VerifyNow_Tooltip") }}</span>
						</v-tooltip>
					</span>

					<v-tooltip bottom v-if="profile.IsMobilePhoneVerified">
						<template v-slot:activator="{ on }">
							<v-icon color="success" size="20" v-on="on">fas fa-check-circle</v-icon>
						</template>
						<span>{{ $t("verifiedNumber") }}</span>
					</v-tooltip>
				</template>
			</v-col>
			<v-col
				cols="12"
				sm="2"
				v-if="!editMobilePhoneSection.showForm"
				class="text-right"
			>
				<v-btn
					class="secondary width-xs-100"
					@click="ShowEditMobilePhoneSection()"
					>{{ $t("common.edit") }}</v-btn
				>
			</v-col>
			<v-col cols="12" sm="5" v-if="editMobilePhoneSection.showForm">
				<div>
					<v-text-field
						v-model="editMobilePhoneSection.mobilePhone"
						:label="$t('MobilePhone')"
            :hint="$t('MobilePhone.hint')"
						:counter="20"
						:error-messages="editMobilePhoneSection.errorsMobilePhoneNo"

					></v-text-field>

					<v-row class="pa-2">
						<v-col cols="12" class="text-right px-3">
							<v-btn
								color="primary"
								class="width-xs-50"
								@click="UpdateUserMobilePhone"
								:loading="editMobilePhoneSection.loadingData"
								>{{ $t("common.update") }}</v-btn
							>

							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										class="ml-3"
										icon
										color="grey"
										@click="HideEditMobilePhoneSection"
										:loading="editMobilePhoneSection.loadingData"
									>
										<v-icon>fas fa-times</v-icon>
									</v-btn>
								</template>
								<span>{{ $t("common.cancel") }}</span>
							</v-tooltip>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
		<VerifyMobileDialog
			v-model="showVerifyMobileDialog"
			:metadataLoaded="metadataLoaded"
			:metadata="metadata"
			:mobilePhoneNo="profile.MobilePhoneNo"
			@VerificationComplete="OnSmsVerificationComplete"
		></VerifyMobileDialog>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VerifyMobileDialog from "./VerifyMobileDialog";
import Account from "@/services/account";

export default {
	components: { VerifyMobileDialog },
	props: {
		metadata: { type: Object },
		metadataLoaded: { type: Boolean },
		profile: { type: Object },
	},
	data() {
		return {
			sendingSMS: false,
			showVerifyMobileDialog: false,
			editNameSection: {
				loadingData: false,
				showForm: false,
				name: "",
				surname: "",
			},
			datePickerProps: {
				max: null,
			},
			editEmailSection: {
				email: "",
				showForm: false,
				loadingData: false,
			},

			editMobilePhoneSection: {
				mobilePhone: "",
				showForm: false,
				loadingData: false,
				errorsMobilePhoneNo: []
			},
		};
	},

	computed: {
		...mapGetters(["isUserInRole"]),
	},
	methods: {
		...mapActions(["LoadUserData", "snackSuccess", "snackError"]),

		ShowEditNameSection() {
			this.HideEmailSection();
			this.HideEditMobilePhoneSection();

			this.editNameSection.showForm = true;
			this.editNameSection.name = this.profile.Name;
			this.editNameSection.surname = this.profile.Surname;
		},
		HideEditNameSection() {
			this.editNameSection.showForm = false;
		},

		ShowEmailSection() {
			this.HideEditNameSection();
			this.HideEditMobilePhoneSection();

			this.editEmailSection.showForm = true;
			this.editEmailSection.email = this.profile.AspNetUser.Email;
		},
		HideEmailSection() {
			this.editEmailSection.showForm = false;
		},

		ShowEditMobilePhoneSection() {
			this.HideEmailSection();
			this.HideEditNameSection();

			this.editMobilePhoneSection.showForm = true;
			this.editMobilePhoneSection.mobilePhone = this.profile.MobilePhoneNo;
		},
		HideEditMobilePhoneSection() {
			this.editMobilePhoneSection.showForm = false;
		},

		async UpdateUserMobilePhone() {
			try {
				this.editMobilePhoneSection.loadingData = true;

				this.editMobilePhoneSection.mobilePhone = this.editMobilePhoneSection.mobilePhone.replace(/\s/g, "");

				this.editMobilePhoneSection.errorsMobilePhoneNo = [];

				if (!this.editMobilePhoneSection.mobilePhone) {
					this.$log.warn("Mobile phone number is required");
					this.editMobilePhoneSection.errorsMobilePhoneNo.push("Il campo cellulare è richiesto");
					return;
				}

				// if it is international phone no like +391231231212 it's ok
				if (!/^\+\d{9,15}$/.test(this.editMobilePhoneSection.mobilePhone)) {
					this.$log.warn("Mobile phone number is not international format");

					// if it is all digits, add +39 and retry validation
					if (/^3\d{8,10}$/.test(this.editMobilePhoneSection.mobilePhone)) {
						this.$log.info("Seems italian mobile phone no");
						this.editMobilePhoneSection.mobilePhone = "+39" + this.editMobilePhoneSection.mobilePhone;
					} else {
						this.$log.warn("not valid phone number");
						this.editMobilePhoneSection.errorsMobilePhoneNo.push("il numero di telefono non è valido");
						return;
					}
				}

				const dto = {};
				dto.MobilePhoneNo = this.editMobilePhoneSection.mobilePhone;
				await Account.Patch(dto);

				this.HideEditMobilePhoneSection();
				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				await this.LoadUserData();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.editMobilePhoneSection.loadingData = false;
			}
		},

		async UpdateUserEmail() {
			try {
				this.editEmailSection.loadingData = true;
				const validationResult = await this.$validator.validateAll("formEmail");
				if (!validationResult) {
					this.$log.info("form not valid");
					return;
				}

				const dto = {};
				dto.Email = this.editEmailSection.email;
				await Account.ChangeEmail(dto);

				this.HideEmailSection();
				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				await this.LoadUserData();
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.editEmailSection.loadingData = false;
			}
		},

		async UpdateNameSurname() {
			try {
				this.editNameSection.loadingData = true;
				const validationResult = await this.$validator.validateAll(
					"formEditAuthor"
				);
				if (!validationResult) {
					this.$log.info("form not valid");
					return;
				}

				const dto = {};
				dto.Name = this.editNameSection.name;
				dto.Surname = this.editNameSection.surname;
				await Account.Patch(dto);

				this.HideEditNameSection();
				this.snackSuccess({ Text: this.$t("common.success.updateData") });
				await this.LoadUserData();
			} catch (err) {
				this.$log.debug(err);
				this.snackError({ Text: this.$t("common.error.cannotUpdateData") });
			} finally {
				this.editNameSection.loadingData = false;
			}
		},

		async StartVerification() {
			try {
				this.sendingSMS = true;
				await Account.RequestMobileVerificationCode();
				this.showVerifyMobileDialog = true;
			} catch (error) {
				this.snackError({ Text: this.$t("smsVerificationCannotSendSMS") });
			} finally {
				this.sendingSMS = false;
			}
		},

		async OnSmsVerificationComplete() {
			this.showVerifyMobileDialog = false;
			await this.LoadUserData();
		},
	},
};
</script>

<style lang="scss" scoped>
.LabelValueGrid {
	.layout.row.wrap {
		min-height: 64px;
	}
}
</style>

<style lang="scss">
.datePicker {
	.v-label,
	.v-input__prepend-outer {
		display: none;
	}
}
</style>

<i18n>
{
    "it": {
		"label_NameSurname": "Nome e Cognome",
		"label_Password": "Password",
		"label_Mobile": "Cellulare",
		"label_Email": "Email",
		"yourSurname": "Il tuo cognome",
		"yourName": "Il tuo nome",
    "MobilePhone": "Il tuo cellulare",
      "MobilePhone.hint":  "il prefisso internazional: +393211231212",
    "verifiedNumber": "Numero verificato",
		"VerifyNow": "Verifica Ora",
		"VerifyNow_Tooltip": "Verifica il numero di telefono",
		"label_DisplayName": "Nome Profilo"
    }
}
</i18n>
